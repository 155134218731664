import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    de: {
      translation: {
        "greeting": "Guten Tag, Memo!",
        "monthlyReport": "Erfolgsrechnung Monat",
        "yearlyReport": "Erfolgsrechnung Jahr",
        "bilance": "Bilanz Status",
        "bilance_kpi": "Bilanz KPIs",
        "netRevenue": "Konzern Nettoumsatz",
        "revenue": "Umsatz Geschäftsbereich",
        "margin": "Deckungsbeitrag Monat",
        "productAnalysis": "Produkt Analyse",
        "regionAnalysis": "Region Analyse",
        "myClients": "Meine Kunden",
        "openAll": "Alle öffnen",
        "closeAll": "Alle schliessen",
        "shortSummary": "Kurz",
        "longSummary": "Lang",
        "loadingSummary": "Deine Zusammenfassung wird geladen...",
        "loadingAudio": "Audio wird geladen",
        'conversation_partner': 'Gesprächspartner',
        'customer_satisfaction': 'Kundenzufriedenheit',
        'comment': 'Kommentar',
        'save': 'Speichern',
        'chat': 'Assistant Lion',
        'chat_welcome_message': '<h3>Wie kann ich Ihnen heute helfen?</h3><p>Ob Sie einen Überblick über aktuelle Absatzzahlen Ihrer Kunden 📊 benötigen oder besser verstehen möchten, wie sich Ihre Umsätze derzeit regional verteilen 🔍 – ich stehe Ihnen zur Verfügung. Teilen Sie mir einfach mit, welche Informationen Sie benötigen, und ich werde die erforderlichen Daten präzise und effektiv bereitstellen. 🚀</p>',
        'ask_question': 'Frage eingeben...',
        'reset-chat': 'Chat löschen',
        'chat-tab': 'Chat',
        'calendar-tab': 'Mein Kalender',
        'very-important-prompts': 'VIP',
        'load-response': 'Lade Antwort...',
        'load-calendar': 'Lade Kalendereinträge...',
        'description': 'Beschreibung',
        'no-events': 'Keine Kalendereinträge vorhanden.',
        'prepare-me-for-meeting': 'Bereite mich auf meinen Termin vor.',
      }
    },
    fr: {
      translation: {
        "greeting": "Bonjour, Memo!",
        "monthlyReport": "Compte de résultat mensuel",
        "yearlyReport": "Compte de résultat annuel",
        "bilance": "Statut du bilan",
        "bilance_kpi": "KPIs du bilan",
        "netRevenue": "Chiffre d'affaires net consolidé",
        "revenue": "Chiffre d'affaires par secteur",
        "margin": "Marge contributive mensuelle",
        "productAnalysis": "Analyse de produit",
        "regionAnalysis": "Analyse régionale",
        "myClients": "Mes clients",
        "openAll": "Ouvrir tout",
        "closeAll": "Fermer tout",
        "shortSummary": "Court",
        "longSummary": "Long",
        "loadingSummary": "Votre résumé est en cours de chargement...",
        "loadingAudio": "Chargement de l'audio en cours",
        'conversation_partner': 'Interlocuteur',
        'customer_satisfaction': 'Satisfaction Client',
        'comment': 'Commentaire',
        'save': 'Enregistrer',
        'chat': 'Assistant Lion',
        'chat_welcome_message': "<h3>Comment puis-je vous aider aujourd'hui ?</h3><p>Que vous ayez besoin d'un aperçu des chiffres de vente actuels de vos clients 📊 ou que vous souhaitiez mieux comprendre la répartition régionale de vos revenus 🔍 – je suis à votre disposition. Faites-moi simplement savoir quelles informations vous nécessitez, et je fournirai les données nécessaires avec précision et efficacité. 🚀</p>",
        'ask_question': 'Entrez une question...',
        'reset-chat': 'Réinitialiser le chat',
        'chat-tab': 'Chat',
        'calendar-tab': 'Mon Calendrier',
        'very-important-prompts': 'VIP',
        'load-response': 'Chargement de la réponse...',
        'load-calendar': 'Chargement des entrées du calendrier...',
        'description': 'Description',
        'no-events': 'Aucune entrée de calendrier disponible.',
        'prepare-me-for-meeting': 'Prépare-moi pour ma réunion.',
      }
    },
    en: {
      translation: {
        "greeting": "Hi, Memo!",
        "monthlyReport": "Monthly Financial Report",
        "yearlyReport": "Annual Financial Report",
        "bilance": "Balance Status",
        "bilance_kpi": "Balance KPIs",
        "netRevenue": "Group Net Revenue",
        "revenue": "Division Revenue",
        "margin": "Monthly Contribution Margin",
        "productAnalysis": "Product Analysis",
        "regionAnalysis": "Regional Analysis",
        "myClients": "My customers",
        "openAll": "Open All",
        "closeAll": "Close All",
        "shortSummary": "Short",
        "longSummary": "Long",
        "loadingSummary": "Your summary is loading...",
        "loadingAudio": "Loading audio",
        'conversation_partner': 'Conversation Partner',
        'customer_satisfaction': 'Customer Satisfaction',
        'comment': 'Comment',
        'save': 'Save',
        'chat': 'Assistant Lion',
        'chat_welcome_message': "<h3>How can I assist you today?</h3><p>Whether you need an overview of your customers' current sales figures 📊 or want to better understand how your revenue is distributed regionally 🔍 – I am here to help. Just let me know what information you need, and I will provide the necessary data precisely and efficiently. 🚀</p>",
        'ask_question': 'Enter a question...',
        'reset-chat': 'Clear chat',
        'chat-tab': 'Chat',
        'calendar-tab': 'My Calendar',
        'very-important-prompts': 'VIP',
        'load-response': 'Loading response...',
        'load-calendar': 'Loading calendar entries...',
        'description': 'Description',
        'no-events': 'No calendar entries available.',
        'prepare-me-for-meeting': 'Prepare me for my meeting.',
      }
    }
  };



i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "de",
    fallbackLng: "de",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
